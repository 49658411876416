@font-face {
    font-family: 'Jack';
    src: url('/public/fonts/Jack.ttf');
  }

  @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.terminalContainer {
  border: 2px solid #00ff00;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  background-color: black;
  margin-top: 0dvw;
}

.terminalScreen {
  font-family: 'VT323', monospace;
  color: #00ff00;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100dvh;
  margin-bottom: 0dvw;
}

.terminalText {
  margin: 0;
  padding: 20px 0;
  word-break: break-word;
  font-size: 2dvw;
  width: 80dvw;
  font-family: 'VT323', monospace;
  color: #00ff00;
  font-weight: 600;
}

.enterButton {
  font-family: 'VT323', monospace;
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10dvw;
  margin-bottom: 0dvw;
}

.noCursor::after {
  display: none;
}

.enterButton:hover {
  background-color: #00ff00;
  color: #000;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.terminalText::after {
  content: '|';
  animation: blink 1s infinite;
}

.content {
  position: relative;
  z-index: 2;
  transition: transform 1s;
}

.content.beamingUp {
  transform: translateY(-20vh);
}

.beamEffect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, 
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%);
  opacity: 0;
  animation: beamAnimation 1s ease-in-out;
  z-index: 3;
}

@keyframes beamAnimation {
  0% {
      opacity: 0;
      transform: translateY(100%);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: translateY(-100%);
  }
}


/* Existing styles for .enterButton, .visible, .hidden, etc. should remain unchanged */

  .welcomecontainer {
    width: 100dvw;
    height: 100%;
    min-height: 100dvh;
    background-color: black;
    align-items: center;
    display: flex;
    justify-content: top;
    flex-direction: column;
    padding: 20px;
    position: fixed;
    background-color: #000;
    border: 2px solid #00ff00;
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
    box-sizing: border-box;
  }

  .starfield {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    perspective: 300px;
}

.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: white;
    border-radius: 50%;
    opacity: 0;
    animation: starAnimation 8s linear infinite;
}

@keyframes starAnimation {
    from {
        transform: translateZ(0px);
        opacity: 0;
    }
    10% {
        opacity: 0.5;
    }
    90% {
        opacity: 0.5;
    }
    to {
        transform: translateZ(300px);
        opacity: 0;
    }
}

  .alientitle {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    text-align: center;
    top: 20%;
    left: 20%;
    font-size: 5vw;
    font-family:"Jack";
    color: #65d340;
  }

  .subtitle {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    text-align: center;
    top: 27%;
    left: 22%;
    font-size: 4vw;
  }

  .subtexts {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    text-align: left;
    top: 32%;
    left: 17%;
    font-size: 3.2dvw;
    width: 50dvw;

  }

  .doc {
    width: 20dvw;
    height: auto;
    position:absolute;
    top: 22%;
    left: 62%;
  }

  .test3 {
    width: 30vw;
    height: auto;
    position: absolute;
    top: 10%;
    left: 62%;
    z-index: 1;
    border-radius: 2vw;
  }

  .test4 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
    border-radius: 2vw;
  }

  .test5 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 60%;
    left: 10%;
    z-index: 1;
    border-radius: 2vw;
  }  
  
.mobilecontainer {
  display: none;
}

.mobilecontainer2 {
  display: none;
}

  .container {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .faqcontainer {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .igcontainer {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerhybrid {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .aliencontainer {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homecontainer {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobilehomecontainer {
    display: none;
  }

  .container3 {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensure the container is relative */
  }
  
  .container2 {
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  .overlay2 {
    position: absolute;
    width: 60%; /* Set width to 60% */
    height: 59%; /* Adjust the height as needed */
    top: 16%; /* Adjust the position as needed */
    left: 20%; /* Center it horizontally */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align to the top to start scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 1px solid black; /* Optional: Add a border for visibility */
    background-color: rgba(0, 0, 0, 1); /* Optional: Add a semi-transparent background for readability */
    z-index: 2; /* Ensure it's above other content */
    transform-origin: top left; /* Set transform origin to top left */
    border-radius: 2vw;
}

.overlay4 {
  position: absolute;
  width: 55%; /* Set width to 60% */
  height: 60%; /* Adjust the height as needed */
  top: 16%; /* Adjust the position as needed */
  left: 22.5%; /* Center it horizontally */
  display: flex;
  justify-content: center;
  align-items: start; /* Align to the top to start scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 2; /* Ensure it's above other content */

}

.overlay {
  position: absolute;
  width: 60%; /* Set width to 60% */
  height: 59%; /* Adjust the height as needed */
  top: 18%; /* Adjust the position as needed */
  left: 20%; /* Center it horizontally */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align to the top to start scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: 1px solid black; /* Optional: Add a border for visibility */
  background-color: rgba(0, 0, 0, 0.7); /* Optional: Add a semi-transparent background for readability */
  z-index: 2; /* Ensure it's above other content */
  transform-origin: top left; /* Set transform origin to top left */
  border-radius: 2vw;
}

.swap3 {
  display: none;
}

.overlayContent {
    width: 100%;
    height: auto;
    transform: scale(0.6); /* Scale the content to fit */
    transform-origin: top left; /* Ensure scaling from top left */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}


  .hybridcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }


  .hybridMap {
    width: 100vw;
    height: 100vh;
    width: 100dvw;
    height: 100dvh;
  }


  .mobileMap {
    width: 100vw;
    height: 100dvh;
    height: 100vh;
  }

  .mapImage {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
  }

  .alertlink {
    font-size: 1dvw;
    color: white;
    text-decoration: none;
  }
  
  .hybridtitle {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 3vw;
    font-family: "Jack";
    top: 22%;
    border-bottom: 8px solid #65d340; /* Adjust the underline color and thickness */
    padding-bottom: 5px; /* Add some padding to space out the text from the underline */
  }
  .hybridtitle2 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2dvw;
    font-family: "Jack";
    top: 18%;
    width: 55dvw;
    background-color: white;
    text-align: center;
  }

  .hybridtitle3 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2dvw;
    font-family: "Jack";
    top: 25%;
    width: 55dvw;
    background-color: white;
    text-align: center;
  }

  .hybridtitle4 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 3vw;
    font-family: "Jack";
    top: 32%;
  }
  
  .visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .faqtitle {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 3vw;
    font-family: "Jack";
    top: 20%;
    left: 22%;
    border-bottom: 8px solid #65d340; /* Adjust the underline color and thickness */
    padding-bottom: 5px; /* Add some padding to space out the text from the underline */
  }

  .faqtext {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2vw;
    font-family: "Jack";
    top: 40%;
    width: 50dvw;
  }

  .alerttext {
    color: #65d340;
    font-size: 1.5dvw;
    font-family: "Jack";
    margin: 0dvw;
  }

  .alerttext2 {
    color: #65d340;
    font-size: 1.5dvw;
    font-family: "Jack";
    margin: 0dvw;
  }

  .alerttext4 {
    color: #65d340;
    font-size: 1.5dvw;
    font-family: "Jack";
    margin: 0dvw;
  }

  .alerttext3 {
    color: white;
    font-size: 1.25dvw;
    font-family: "Jack";
    text-decoration: underline;
    text-decoration-thickness: .2dvw;
    text-underline-offset: .25dvw;
    text-decoration-color: white;
  }

  .alertsbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 55dvw;
    height: 38dvh;
    justify-content: top;
    align-items: left;
    top: 36%;
    gap: 2dvw;
  }

  .faqbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 55dvw;
    height: 38dvh;
    justify-content: top;
    align-items: left;
    top: 36%;
    gap: 2dvw;
  }

  .hubtitle {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 3vw;
    font-family: "Jack";
    top: 22%;
    border-bottom: 8px solid #65d340; /* Adjust the underline color and thickness */
    padding-bottom: 5px; /* Add some padding to space out the text from the underline */
  }

  .hubgrid {
    position: absolute;
    z-index: 2;
    top: 34%;
    left: 26%;
    width: 55%;
    height: 40%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr);    /* 2 rows */
    justify-content: center;
    align-items: center;
    gap: 1dvw;
  }

  .hubitem {
    color: white;
    font-size: 30px;
    background-color: rgba(26, 26, 26, 0.624);
    width: 20dvw;
    height: 18dvh;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    border-radius: 2vw;
    font-weight: 600;
    padding: 2vw;
    background-image: url('/public/images/website.png');
    background-size: 25vw;
  }

  .hybridtext {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2vw;
    font-family: "Jack";
    top: 42%;
  }

  .hybridtext1 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2vw;
    font-family: "Jack";
    top: 60%;
    line-height: 3vw;
    width: 50vw;
    text-align: center;
  }
  

  .test3 {
    width: 30vw;
    height: auto;
    position: absolute;
    top: 10%;
    left: 62%;
    z-index: 1;
    border-radius: 2vw;
  }
  
  .test4 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
    border-radius: 2vw;
  }

  .mobilehomecontainer2 {
    display: none;
  }
  
  .test5 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 60%;
    left: 10%;
    z-index: 1;
    border-radius: 2vw;
  }
  
  .aliencontainer2 {
    display: none;
  }

  .mobilehomecontainer3 {
    display: none;
  }

  .mobileteam {
    display: none;
  }

  @media (max-width: 768px) {
    .container {
      width: 100vw;  /* Full viewport width */
      height: 100vh; /* Full viewport height */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; /* Prevent scrolling */
      position: relative;
      transform: scale(0.9); /* Scale down the content */
      transform-origin: top left; /* Ensure scaling from the top left corner */
  }

    .mobileteam {
      width: 100vw;  /* Full viewport width */
      height: 100vh; /* Full viewport height */
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; /* Prevent scrolling */
      position: relative;
      transform: scale(0.9); /* Scale down the content */
      transform-origin: top left; /* Ensure scaling from the top left corner */
  }


  .hybridMap {
      width: 100vw;  /* Full viewport width */
      height: 100vh; /* Full viewport height */
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden; /* Prevent scrolling */
      transform: scale(1); /* Scale down the content */
      transform-origin: top left; /* Ensure scaling from the top left corner */
  }

  .hybridMap svg {
      width: 100vw;  /* Full viewport width */
      height: 100vh; /* Full viewport height */
      object-fit: contain; /* Contain the SVG within the container */
  }
  
  body, html {
      overflow: hidden; /* Prevent scrolling on the body */
      height: 100%; /* Full height to ensure the container touches the bottom */
      margin: 0; /* Remove default margins */
      padding: 0; /* Remove default padding */
      background-color: black;
  }

  .hubgrid {
    position: absolute;
    z-index: 2;
    top: 35%;
    left: 22.5%;
    width: 60%;
    height: 35%;
    display: grid;
    margin-bottom: 0px;
    margin-top: 0px;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr);    /* 2 rows */
    justify-content: center;
    align-items: center;
    gap: 1dvw;
  }

  .hybridtext {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2vw;
    font-family: "Jack";
    top: 42%;
  }

  .hybridtext1 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 2vw;
    font-family: "Jack";
    top: 52%;
    line-height: 3vw;
    width: 50vw;
    text-align: center;
  }

  .container3 {
    display: none;
  }

  .mobilecontainer {
    display: flex;
    width: 100vw;
    height: auto;
    overflow: auto;
  }

  .mobilehomecontainer {
    width: 100vw;  /* Full viewport width */
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobilehomecontainer2 {
    width: 100vw;  /* Full viewport width */
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mobilehomecontainer3{
    width: 100vw;  /* Full viewport width */
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .homecontainer {
    display: none;
  }

  .overlayMap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    pointer-events: none; /* Ensure clicks pass through to the underlying elements */
    z-index: 1;
  }
  
  .overlayMap a {
    pointer-events: auto; /* Allow clicks on the links */
  }

  .aliencontainer {
    display: none;
  }

  .aliencontainer2 {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .magiceden1 {
    font-size: 3vw;
    color: #65d340;
    text-decoration: none;
    background: rgb(44, 44, 44);
    background-size: contain;
    width: fit-content;
    padding: 1.5vw;
    border-radius: 2vw;
    background-image: url('/public/images/website.png');
  }

  .linkgrid {
    grid-template-columns: repeat(2, 1fr); /* Adjust to ensure grid items are flexible */
    width: 70dvw;
    display: grid;
    position: absolute;
    top: 65%;
    left: 23%;
    gap: 5vw; 
    font-family: "Jack";
  }

  .containerhybrid {
    display: none;
  }

  .igcontainer {
    display: none;
  }

  .hubtitle {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 4vw;
    font-family: "Jack";
    top: 22%;
    left: 24%;
    border-bottom: 8px solid #65d340; /* Adjust the underline color and thickness */
    padding-bottom: 5px; /* Add some padding to space out the text from the underline */
  }


  .hubitem {
    color: white;
    font-size: 4dvw;
    background-color: rgba(26, 26, 26, 0.624);
    width: 27dvw;
    height: 30dvw;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    border-radius: 2vw;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 2vw;
    background-image: url('/public/images/website.png');
  }

  .faqcontainer {
    display: none;
  }

    .faqtitle2 {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 5vw;
    font-family: "Jack";
    top: 21%;
    left: 22%;
    border-bottom: 8px solid #65d340; /* Adjust the underline color and thickness */
    padding-bottom: 5px; /* Add some padding to space out the text from the underline */
  }

  .faqtext {
    position: absolute;
    z-index: 2;
    color: #65d340;
    font-size: 4vw;
    font-family: "Jack";
    top: 46%;
    left: 23%;
    width: 60dvw;
  }


  .alerttext {
    color: #65d340;
    font-size: 3.5dvw;
    font-family: "Jack";
    
    width: 60dvw;
  }

  .alerttext2 {
    color: #65d340;
    font-size: 3.5dvw;
    font-family: "Jack";
    width: 60dvw;
  }

  .alertsbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 50dvw;
    height: 38dvh;
    justify-content: top;
    align-items: left;
    top: 34%;
    gap: 5dvw;
    margin-top: 1dvw;
  }

  .faqbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60dvw;
    height: 38dvh;
    justify-content: top;
    align-items: left;
    top: 32%;
    left: 22%;
    gap: 5dvw;
    margin-top: 1dvw;
  }

  .alertlink {
    font-size: 3dvw;
    color: white;
    text-decoration: none;
  }

  .alerttext4 {
    color: #65d340;
    font-size: 3.25dvw;
    font-family: "Jack";
    margin: 0dvw;
  }

  .alerttext3 {
    color: white;
    font-size: 3dvw;
    font-family: "Jack";
    text-decoration: underline;
    text-decoration-thickness: .2dvw;
    text-underline-offset: .5dvw;
    text-decoration-color: white;
  }

  .overlay2 {
    position: absolute;
    width: 60%; /* Set width to 60% */
    height: 55%; /* Adjust the height as needed */
    top: 22%; /* Adjust the position as needed */
    left: 21.5%;
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align to the top to start scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 1px solid black; /* Optional: Add a border for visibility */
    z-index: 2; /* Ensure it's above other content */
    transform-origin: top left; /* Set transform origin to top left */
    border-radius: 2vw;
}

.overlay4 {
  position: absolute;
  width: 60%; /* Set width to 60% */
  height: 56.5%; /* Adjust the height as needed */
  top: 21.5%; /* Adjust the position as needed */
  left: 21.5%; /* Center it horizontally */
  display: flex;
  justify-content: center;
  align-items: start; /* Align to the top to start scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 2; /* Ensure it's above other content */
}

.terminalContainer {
  border: 2px solid #00ff00;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  background-color: black;
  height: 100%;
}

.terminalScreen {
  font-family: 'VT323', monospace;
  color: #00ff00;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  width: 100dvw;

}

.terminalText {
  margin: 0;
  padding: 5px 0;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 6dvw;
  width: 90dvw;
  margin-bottom: 5dvw;
}

.enterButton {
  font-family: 'VT323', monospace;
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20dvw;
  align-self: center;
  margin-bottom: 0dvw;
}
  

}
  
 
  
