@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/a11y-dark.min.css');

.conversation-library {
  width: 25dvw;
  background-color: #1f2937; /* bg-gray-900 */
  padding: 1rem; /* p-4 */
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  border-right: 1px solid #374151; /* border-gray-700 */
}

.conversation-title {
  font-size: 1.25rem; /* text-xl */
  font-weight: bold;
  margin-bottom: 1rem; /* mb-4 */
  color: #10b981; /* text-green-400 */
}

.conversation-list {
  margin-bottom: 1rem; /* mb-4 */
}

.conversation-item {
  cursor: pointer;
  color: #ffffff; /* text-white */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem; /* mb-2 */
}

.conversation-item:hover {
  color: #10b981; /* hover:text-green-400 */
}

.renaming-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.renaming-input {
  background-color: #374151; /* bg-gray-800 */
  color: #ffffff; /* text-white */
  padding: 0.25rem; /* p-1 */
  border-radius: 0.25rem; /* rounded */
  width: 100%;
  margin-right: 0.5rem; /* mr-2 */
}

.confirm-btn {
  color: #10b981; /* text-green-500 */
  margin-right: 0.5rem; /* mr-2 */
}

.cancel-btn {
  color: #ef4444; /* text-red-500 */
}

.edit-btn {
  color: #3b82f6; /* text-blue-300 */
  padding: 0.5rem; /* p-2 */
  font-size: 0.875rem; /* text-sm */
  margin: 0 0.5rem; /* mx-2 */
}

.delete-btn {
  color: #ef4444; /* text-red-300 */
  padding: 0.5rem; /* p-2 */
  font-size: 0.875rem; /* text-sm */
  margin: 0 0.5rem; /* mx-2 */
}

.new-chat-btn {
  width: 100%; /* w-full */
  background-color: #047857; /* bg-green-600 */
  color: #ffffff; /* text-white */
  font-weight: bold;
  padding: 0.5rem 0.75rem; /* py-2 px-3 */
  border-radius: 0.25rem; /* rounded */
  transition: background-color 0.3s; /* transition duration-300 */
  font-size: 0.75rem; /* text-[12px] */
  position: relative;
}

.new-chat-btn:hover {
  background-color: #065f46; /* hover:bg-green-700 */
}

.tos-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  pointer-events: auto;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  opacity: 1 !important;
}

.popup {
  background-color: #121212; /* Solid background */
  border: 2px solid #00ff00;
  border-radius: 10px;
  padding: 20px;
  width: 80dvw;
  max-height: 80vh; /* Prevents the popup from being too tall */
  overflow-y: auto; /* Allows scrolling if content overflows */
  box-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00;
  text-align: left;
  color: #ffffff; /* Text color */
  line-height: 1.5; /* Improve readability */
  font-size: 18px; /* Adjust the font size */
  animation: glowingEdge 2s infinite linear;
}

.popup h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.popup pre {
  white-space: pre-wrap; /* Allows wrapping of long lines */
  font-family: inherit; /* Uses the same font as the rest of the popup */
  color: #00ff00;
}

.popup ul, .popup ol {
  margin-left: 20px;
  padding-left: 20px;
}

.popup li {
  margin-bottom: 10px;
}


.close-popup-button,
.submit-bug-button {
  background-color: #00ff00;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.close-popup-button:hover,
.submit-bug-button:hover {
  background-color: #008000;
}

.bug-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00ff00;
  background-color: #333;
  color: white;
}

.tos-content {
  background-color: #1a1a1a;
  color: #00ff00;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
  position: relative;
  z-index: 100001;
}

.tos-content h2 {
  margin-bottom: 1rem;
}

.tos-content ul {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.tos-content button {
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  z-index: 100002;
}

.chat-container {
  scroll-behavior: smooth;
}

.tos-content button:hover {
  background-color: #00cc00;
}

.ai-interface {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
  color: #00ff00;
  font-family: 'Orbitron', sans-serif;
  position: relative;
  overflow: hidden;
  font-weight: 600;
}

.ai-interface2 {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: transparent;
    color: #00ff00;
    font-family: 'Orbitron', sans-serif;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    width: 100dvw;
  }

.message-content pre {
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
  overflow-x: auto;
}

.message-content code {
  font-family: 'Courier New', Courier, monospace;
  color: #00ff00;
}

.message-content p {
  margin-bottom: 10px;
}

.message-content br {
  display: block;
  margin: 5px 0;
  content: "";
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.ai-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.wallet-adapter-button {
  align-self: center !important;
  margin: auto !important;
}

.ai-header {
  background-color: #1a1a1a;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333;
  opacity: .9;
  width: 100%;
}

.ai-header h1 {
  color: #00ff00;
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  font-family: 'Orbitron', sans-serif;
}


.connect-prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #00ff00;
  background-color: black;
  width: 100vw;
}

.connected-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.animated-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #111, #222);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  z-index: -2;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}



.bottombar {
    display:flex;
    width: 100%;
    display: row;
    position:absolute;
    bottom: 0;
}

#conversation-library.hidden {
  display: none;
}

.sidebar {
    height: 100%;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
}

main {
  display: flex;
  flex: 1;
  overflow: hidden;
}

#chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.input-area {
  background-color: #1a1a1a;
  border-top: 1px solid #333;
  padding: 10px;
  opacity: .9;
  margin-top: 0;
  margin-bottom: 0;
  height: fit-content;
}

.input-area-expanded {
    width: 100dvw;
}

.menu-btn {
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
  font-size: 15px; 
  display: flex;
  margin-left: 10px;
}

.show-menu-btn {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: auto;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-area3 {
    background-color: #1a1a1a;
    border-top: 1px solid #333;
    padding: 20px;
    opacity: .9;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15dvw;
    align-items: center;
  }

  .hidden {
    display: none;
  }



.input-row2 {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  padding: 0;
}

.input-row3 {
    display: flex;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: top;
    gap: 0;
    height: 100%;
    justify-content:space-between;
    margin-top: 0px;
    width: 100%;
    
  }

.input-row {
  display: flex;
  margin-bottom: 0px;
  position: relative;
  height: fit-content;
  justify-content: center;
  margin-top: 0;
  align-items: center;
}

.user-input {
  flex: 1;
  background-color: #2a2a2a;
  border: 1px solid #444;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  width: 90%;
  height: 10%;
}

.send-btn {
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
  font-size: 15px; 
  display: flex;
}

.send-btn:hover {
  background-color: #00cc00;
  box-shadow: 0 0 10px #00ff00;
}



.fetch-token-btn, .toggle-library-btn, .toggle-menu-btn, .upload-btn, .voice-toggle-btn, .chart-tool-btn, .meme-chart-btn, .jupiter-btn, .bug-report-btn, .help-btn, .stop-btn {
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 10px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  
}

 .fetch-token-btn:hover, .toggle-library-btn:hover, .toggle-menu-btn:hover, .upload-btn:hover, .voice-toggle-btn:hover, .chart-tool-btn:hover, .meme-chart-btn:hover, .jupiter-btn:hover, .bug-report-btn:hover, .help-btn:hover, .stop-btn:hover {
  background-color: #00cc00;
  box-shadow: 0 0 10px #00ff00;
}

.voice-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.voice-toggle input[type="checkbox"] {
  margin-right: 10px;
}

.voice-toggle-btn {
  background-color: #00ff00;
  color: #000;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.voice-toggle-btn:hover {
  background-color: #00cc00;
  box-shadow: 0 0 10px #00ff00;
}
.image-upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* This will allow the buttons to wrap to the next line */
  max-width: 75%;
}

.image-upload3 {
    display: flex;
    margin-bottom: 5px;
    align-items: left;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: left;
    
  }

  .image-upload span3 {
    margin-left: 10px;
    color: #888;
  }

.image-upload span {
  margin-left: 10px;
  color: #888;
}

.message {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.message.user {
  background-color: rgba(255, 255, 255, 0.1);
}

.message.assistant {
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Glow effect */
.glow-effect {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00ff00, 0 0 20px #00ff00, 0 0 35px #00ff00;
  transition: all 0.3s ease;
}

.glow-effect:hover {
  box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00ff00, 0 0 20px #00ff00, 0 0 35px #00ff00, 0 0 40px #00ff00;
}

.what {
  width: 100px;
}

/* Starfield2.module.css */
.starfield {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1; /* Ensure it is behind the content */
    overflow: hidden;
    perspective: 1000px;
}

.star {
    position: absolute;
    width: 1px;
    height: 1px;
    background: white;
    border-radius: 50%;
    animation: moveStar 10s linear infinite, twinkle 6s infinite ease-in-out;
}

@keyframes moveStar {
    0% {
        transform: translate3d(0, 0, 0) scale(0.5);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 1000px) scale(1);
        opacity: 0;
    }
}

@keyframes twinkle {
    0%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
}

.glowingStar {
    position: absolute;
    width: 2px;
    height: 2px;
    background: transparent;
    border-radius: 50%;
    animation: moveStar 10s linear infinite, twinkle 6s infinite ease-in-out;
}

.glow {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 80%);
}

/* Nebula Effect */
.nebula {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 20% 40%, rgba(243, 6, 6, 0.2), transparent),
                radial-gradient(circle at 80% 60%, rgba(13, 13, 244, 0.2), transparent),
                radial-gradient(circle at 50% 80%, rgba(16, 244, 16, 0.2), transparent);
    z-index: -1;
    animation: moveNebula 60s infinite linear;
}

@keyframes moveNebula {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.4;
    }
    100% {
        transform: scale(1);
        opacity: 0.6;
    }
}

/* Comet */
.comet {
    position: absolute;
    width: 4px;
    height: 4px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    border-radius: 50%;
    animation: moveComet 10s linear infinite;
}

@keyframes moveComet {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate3d(100vw, 100vh, 0) scale(0.5);
        opacity: 0;
    }
}

@media (max-width: 1400px) {
  .ai-header {
    background-color: #1a1a1a;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
    opacity: .9;
    width: 100%;
    height: fit-content;
  }

  .wallet-adapter-button {
    align-self: center !important;
    margin: auto !important;
    font-size: 1.0rem;
  }
  
  
  .ai-header h1 {
    color: #00ff00;
    margin: 0;
    font-size: 1.0rem;
    font-weight: 600;
    font-family: 'Orbitron', sans-serif;
  }

    .input-row {
        display: flex;
        margin-bottom: 10px;
      }

      .ai-interface2 {
        display: flex;
        flex-direction: row;
        flex: 1;
        background-color: transparent;
        color: #00ff00;
        font-family: 'Orbitron', sans-serif;
        position: relative;
        overflow: hidden;
        font-weight: 600;
        width: 100dvw;
      }

      .fetch-token-btn, .toggle-library-btn, .toggle-menu-btn, .upload-btn, .voice-toggle-btn, .chart-tool-btn, .meme-chart-btn, .jupiter-btn, .bug-report-btn, .help-btn, .stop-btn {
        background-color: #00ff00;
        color: #000;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 12px;
        width: 20dvw;
        height: 14dvw;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      
       .fetch-token-btn:hover, .toggle-library-btn:hover, .toggle-menu-btn:hover, .upload-btn:hover, .voice-toggle-btn:hover, .chart-tool-btn:hover, .meme-chart-btn:hover, .jupiter-btn:hover, .bug-report-btn:hover, .help-btn:hover, .stop-btn:hover {
        background-color: #00cc00;
        box-shadow: 0 0 10px #00ff00;
      }

      .input-area3 {
        align-content: center;
        opacity: .9;
        height: fit-content;
        display: flex;
        justify-content: top;
        width: 50dvw;
        align-items: center;
        background-color: transparent;
        border-top: none;
      }

      .hidden {
        display: none;
      }
      
      .input-row3 {
        display: grid;
        grid-template-columns: 1fr 1fr; /* Two equal-width columns */
        grid-template-rows: 1fr 1fr; /* Two equal-height rows */
        height: fit-content;
        width: auto;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        align-items: center;
        justify-content: center;
        gap: 20px;
       
        border-top: none;
    }

      .conversation-library {
        width: 50dvw;
        background-color: #1f2937; /* bg-gray-900 */
        padding: 1rem; /* p-4 */
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
        border-right: 1px solid #374151; /* border-gray-700 */
      }

      #conversation-library.hidden {
        display: none;
      }
      
      .conversation-title {
        font-size: 1.25rem; /* text-xl */
        font-weight: bold;
        margin-bottom: 1rem; /* mb-4 */
        color: #10b981; /* text-green-400 */
      }
      
      .conversation-list {
        margin-bottom: 1rem; /* mb-4 */
      }
      
      .conversation-item {
        cursor: pointer;
        color: #ffffff; /* text-white */
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem; /* mb-2 */
      }
      
      .conversation-item:hover {
        color: #10b981; /* hover:text-green-400 */
      }
      
      .renaming-container {
        display: flex;
        align-items: center;
        width: 100%;
      }
      
      .renaming-input {
        background-color: #374151; /* bg-gray-800 */
        color: #ffffff; /* text-white */
        padding: 0.25rem; /* p-1 */
        border-radius: 0.25rem; /* rounded */
        width: 100%;
        margin-right: 0.5rem; /* mr-2 */
      }
      
      .confirm-btn {
        color: #10b981; /* text-green-500 */
        margin-right: 0.5rem; /* mr-2 */
      }
      
      .cancel-btn {
        color: #ef4444; /* text-red-500 */
      }
      
      .edit-btn {
        color: #3b82f6; /* text-blue-300 */
        padding: 0.5rem; /* p-2 */
        font-size: 0.875rem; /* text-sm */
        margin: 0 0.5rem; /* mx-2 */
      }
      
      .delete-btn {
        color: #ef4444; /* text-red-300 */
        padding: 0.5rem; /* p-2 */
        font-size: 0.875rem; /* text-sm */
        margin: 0 0.5rem; /* mx-2 */
      }
      
      .new-chat-btn {
        width: 100%; /* w-full */
        background-color: #047857; /* bg-green-600 */
        color: #ffffff; /* text-white */
        font-weight: bold;
        padding: 0.5rem 0.75rem; /* py-2 px-3 */
        border-radius: 0.25rem; /* rounded */
        transition: background-color 0.3s; /* transition duration-300 */
        font-size: 0.75rem; /* text-[12px] */
        position: relative;
      }
      
      .new-chat-btn:hover {
        background-color: #065f46; /* hover:bg-green-700 */
      }
    
    
}