@font-face {
  font-family: 'Jack';
  src: url('/public/fonts/Jack.ttf');
}

.container {
    width: 100vw;  /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container2 {
    width: 100vw;
    height: 100vh;
    position: relative; /* Establish a stacking context */
   
    
    display: flex;
  }
  
  .mobile {
    width: 100vw;
    height: 100vh;
    position: absolute; /* Position relative to container2 */
    top: 0;
    left: 0;
    z-index: 1; /* Behind the test class */
  }
  
  .test {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    font-size: 24px; /* Example font size */
    text-align: center;
    top: 5%;
    left: 12%;
    font-size: 5vw;
    font-family:"Jack";
    color: green;
  }

  .test2 {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    font-size: 24px; /* Example font size */
    text-align: center;
    top: 10%;
    left: 12%;
    font-size: 2vw;
  }

  .test6 {
    position: absolute; /* Needed to overlay on top of the image */
    z-index: 2; /* Higher z-index to be on top */
    color: white; /* Assuming you want the text to be visible on a potentially dark image */
    font-size: 24px; /* Example font size */
    text-align: center;
    top: 24%;
    left: 9%;
    font-size: 2vw;
  }

  .test3 {
    width: 30vw;
    height: auto;
    position: absolute;
    top: 10%;
    left: 62%;
    z-index: 1;
    border-radius: 2vw;
  }

  .test4 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 40%;
    left: 30%;
    z-index: 1;
    border-radius: 2vw;
  }

  .test5 {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 60%;
    left: 10%;
    z-index: 1;
    border-radius: 2vw;
  }