@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
/* ImageScroller.css */
.alien3 {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
  }
  
  .map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  .aliennav {
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-bottom: 10px;
    position: absolute;
    top: 14%;
    left: 35%;
    z-index: 15;
  }
  
  .alienNavText {
    color: white;
    margin-right: 1rem;
    font-size: 1.5vw;
    margin-left: 1.5rem;
  }
  
  .testerr {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  
  .sheetLink {
    font-size: 1.6dvw;
    margin-left: 2vw;
    margin-top: 1vw;
    position: fixed;
    top: 18%;
    left: 28%;
    color: white;
  }
  
  .sheetLink2 {
    font-size: 1.6dvw;
    margin-top: 1vw;
    margin-left: 0vw;
    position:fixed;
    top: 18%;
    left: 21.25%;
    color: white;
  }

  .mobilehomecontainer3 {
    display: none;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% / 3));
    }
  }
  @media (max-width: 768px) {
    .mobilehomecontainer3 {
      width: 100vw;  /* Full viewport width */
      height: 100dvh;
      position: fixed;
      overflow: hidden;
      display: contents;
    }

    .alien3 {
      display: none;
    }
    .mapImage {
      width: 100vw;
      height: 100dvh;
      display: flex;
      position: fixed;
    }

    .sheetLink {
      font-size: 2.25dvw;
      margin-left: 2vw;
      margin-top: 1vw;
      position: fixed;
      top: 22%;
      left: 26%;
      color: white;
    }
    
    .sheetLink2 {
      font-size: 2.25dvw;
      margin-top: 1vw;
      margin-left: 0vw;
      position:fixed;
      top: 22%;
      left: 18.5%;
      color: white;
    }
  }
  