@font-face {
    font-family: 'Jack';
    src: url('/public/fonts/Jack.ttf');
  }

.portfolio-page {
    padding: 2vw;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2vw;
    border-color: black;
    border-style: solid;
    border-width: .15vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: top;
    width: 50vw;
    text-align: center;
    margin-top: 0dvw;
    flex-wrap: wrap;
    box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
  }
  
  .portfolio-page h1 {
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    font-size: 3vw;
    margin-bottom: .5dvw;
    text-align: center;
    margin-top: 0dvw;
    font-family: "Jack";
    color: #65d340;
  }
  
  .portfolio-page h3 {
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
    text-align: center;
    margin-bottom: 0dvw;
    margin-top: 0dvw;
    
  }
  
  .portfolio-page p {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    width: auto;
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  }
  
  .customwallet {
    margin-top: 0vw !important; 
    background-color: white !important;
    margin: 0vw;
    line-height: 1vw;
  }

  .wallet-adapter-button {
    margin-top: 20px !important;
    background-color: #4CAF50 !important; /* Change background color */
    color: white !important; /* Change text color */
    border: none !important; /* Remove border */
    border-radius: 4px !important; /* Add rounded corners */
    padding: 10px 24px !important; /* Adjust padding */
    font-size: 16px !important; /* Change font size */
    cursor: pointer !important; /* Change cursor on hover */
}

.morphtoken {
    width: 8dvw;
    height: 8dvw;
    margin-bottom: .5dvw;
    margin-top: 0dvw;
}

.wallet-adapter-button:hover {
    background-color: #45a049 !important; /* Change background color on hover */
}

.wallet-adapter-button-start-icon {
    display: none !important; /* Hide the start icon if needed */
}
  @media (max-width: 768px) {
    .portfolio-page {
        padding: 2vw;
        color: white;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 2vw;
        border-color: black;
        border-style: solid;
        border-width: .15vw;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: top;
        width: 55vw;
        text-align: center;
        margin-top: 0dvw;
        flex-wrap: wrap;
        box-shadow: 0.15vw 0.15vw 0.5vw .1vw rgb(0, 0, 0);
      }
      
      .portfolio-page h1 {
        text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
        font-size: 4.25dvw;
        margin-bottom: 0dvw;
        text-align: center;
        margin-top: 0dvw;
      }
      
      .portfolio-page h3 {
        text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-bottom: 0dvw;
        margin-top: 0dvw;
      }
      
      .portfolio-page p {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        width: auto;
        text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
      }

      .morphtoken {
        width: 10dvw;
        height: 10dvw;
        margin-bottom: 1dvw;
        margin-top: 1dvw;
    }
  
    }