.gov-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  padding: 1.2vw;
}

.gov-nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
  font-size: 1.2vw;
}

.gov-nav2 {
  display: none;
}

.gov-nav a:hover {
  text-decoration: underline;
}

.gov-nav button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.gov-nav button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .gov-nav {
    display: none;
  }
  
  .gov-nav2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    height: 20vw;
    width: 100dvw;
    position: relative;
    top: 0;
    z-index: 1000;
    padding: 0 1vw;
  }

  .menu-toggle2 {
    display: block;
    cursor: pointer;
  }

  .navbar2-links2 {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 10vw;
    right: 0;
    background-color: rgba(0, 0, 0, 1);
    width: 100vw;
    height: calc(100vh - 10vw);
    z-index: 999;
    padding: 2vw;
  }

  .navbar2-links2.open {
    display: flex;
  }

  .navbar2-links2 ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .navbar2-links2 ul li,
  .navbar2-links2 ul h2 {
    width: 100%;
    text-align: center;
    margin: 1vw 0;
  }

  .navbar2-links2 a {
    color: white;
    text-decoration: none;
    font-size: 5vw;
    display: block;
    padding: 2vw;
  }

  .navbar2-links2 a:hover {
    background-color: #333;
  }

  .bar,
  .bar2,
  .bar3,
  .bar4 {
    display: block;
    width: 25px;
    height: 3px;
    margin: 6px;
    background-color: white;
    transition: all 0.3s ease;
  }
}