.balanceContainer {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2vw;
    color: #65d340; /* Change this color based on your design */
    margin-top: 0px;
    margin-bottom: 0px;
}


@media (max-width: 768px) {
    .balanceContainer {
        text-align: center;
        margin-top: 20px;
        font-size: 3.5vw;
        color: #65d340; /* Change this color based on your design */
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
    