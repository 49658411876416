/* Tokenomics.module.css */
html, body {
  width: 100%;
  height: 100%;
  overflow: auto; /* Ensure body is scrollable */
}

@font-face {
  font-family: 'Jack';
  src: url('/public/fonts/Jack.ttf');
}

.chartContainer {
  width: 70vw; /* Adjust as needed for desired width */
  height: 50vh; /* Adjust as needed for desired height */
  margin: 0 auto; /* Center the chart container */
}

.solscan {
  display: flex;
  justify-content: center;
}

.solscanimage {
  width: 20vw;
  height: 10vw;
}

.sectionHeading {
  font-size: 2.5rem; /* Use a consistent font size */
  color: #65d340; /* Neon-like color for headings */
  margin-bottom: 1rem; /* Consistent bottom margin */
  text-align: center; /* Center-align the text */
  margin-top: 0px;
  font-family: "Jack";
}

.swap3 {
  text-align: center;
  font-size: 1.5dvw; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 2vw;
}

.swap6 {
  display: none;
}

.container {
  width: 100%;
  min-height: 100vh; /* Ensure it can grow */
  overflow-y: auto; /* Ensure vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  color: white;
  margin-top: 0px;
  background-image: url('/public/images/website.png');
}

.title {
  text-align: center;
  margin-bottom: 3rem; /* Increased margin for better spacing */
  font-size: 3rem; /* Larger font size for title */
  color: #65d340; /* Neon-like color for title */
  margin-top: 0px;
  font-family: "Jack";
}

.hero {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust as needed */
  margin-top: 2rem;
}

.heroimage {
  width: 10vw;
  height: auto;
  margin-bottom: 0px;
  margin: auto;
}

.content {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  margin-top: 0px;
  padding: 1rem; /* Add padding for better layout */
}

.swap {
  text-align: center;
  font-size: 1.5dvw; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 2vw;
}

.swap2 {
  text-align: center;
  font-size: 1.2rem; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 2vw;
  margin-bottom: 0px;
}

.tokelinks {
  display: flex;
  flex-direction: row;
  width: 50dvw;
  margin-right: 2.5dvw;
  justify-content: space-around;
}

.content2 {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  margin-top: 0px;
  padding: 1rem; /* Add padding for better layout */
}

.heading {
  font-size: 2rem;
  color: #65d340; /* A neon-like color for headings */
  margin-bottom: 1rem;
}

.infoBlock, .chart, .distribution {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.link {
  word-break: break-all; /* This will ensure that even long URLs will wrap and not cause overflow */
  color: green;
}

.link:hover {
  text-decoration: underline;
}

.overviewSection {
  background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem; /* Add space between sections */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overviewSection2 {
  background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem; /* Add space between sections */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overviewHeading {
  font-size: 2.5rem; /* Larger font size for headings */
  color: #65d340;
  margin-bottom: 1.5rem; /* Increased margin for better spacing */
}

.overviewContent {
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Adjust to ensure grid items are flexible */
  gap: 1.5rem; /* Increased gap for better spacing */
  width: 100%; /* Ensure the grid takes up the full width of the container */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  overflow: hidden; /* Prevent overflow */
  transform-origin: top left; /* Ensure scaling from top left */
  padding: 0 1rem; /* Add padding for better layout */
}

.overviewItem {
  background: rgba(255, 255, 255, 0.1); /* Subtle background for each item */
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  word-wrap: break-word;
}

.longLink {
  display: block; /* Makes the link display as a block */
  overflow-wrap: break-word; /* Allows the link to break onto the next line */
}

.overviewText {
  font-size: 1.1rem; /* Slightly larger font for readability */
  line-height: 1.6; /* Improved line height for readability */
}

.chartContainer {
  width: 50vw; /* Max width for chart container */
  margin: 0 auto; /* Center the chart container */
  height: 30vw; /* Set height to auto for responsive height */
}

.distributionSection {
  background: rgba(0, 0, 0, 0.8); /* Consistent background for sections */
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

.distributionHeading {
  font-size: 2rem;
  color: #65d340; /* Neon-like color for headings */
  margin-bottom: 1rem;
}

.distributionContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
}

.distributionItem {
  background: rgba(255, 255, 255, 0.1); /* Subtle background for each item */
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  word-wrap: break-word;
}

.distributionText {
  color: white;
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 1200px) {
.overviewContent {
  grid-template-columns: repeat(1, minmax(300px, 1fr)); /* Three columns for medium screens */
}
}

@media (max-width: 900px) {
.overviewContent {
  grid-template-columns: repeat(1, minmax(300px, 1fr)); /* Two columns for small screens */
}
}

@media (max-width: 768px) {
  .overviewItem, .listItem {
    background: rgba(255, 255, 255, 0.1); /* Subtle background for each item */
    padding: 15px;
    border-radius: 8px;
    word-wrap: break-word;
    width: 60vw;
  }

  .overviewText, .listItem {
    color: white;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .overviewContent {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 0rem; /* Increased gap for better spacing */
    width: 100dvw; /* Ensure the grid takes up the full width of the container */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    overflow: hidden; /* Prevent overflow */
    transform-origin: top left; /* Ensure scaling from top left */
    padding: 0 1rem; /* Add padding for better layout */
    align-items: center;
    justify-content: center;

  }

  .overviewSection {
    background: rgba(0, 0, 0, 0.8); /* Consistent background for sections */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 3rem;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    
  }

  .overviewSection2 {
    background: rgba(0, 0, 0, 0.8); /* Consistent background for sections */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 0rem;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    text-align: center;
    margin-bottom: 1rem; /* Increased margin for better spacing */
    font-size: 2rem; /* Larger font size for title */
    color: #65d340; /* Neon-like color for title */
    margin-top: 0px;
    font-family: "Jack";
  }

  .sectionHeading {
    font-size: 2rem; /* Use a consistent font size */
    color: #65d340; /* Neon-like color for headings */
    margin-bottom: 1rem; /* Consistent bottom margin */
    text-align: center; /* Center-align the text */
    margin-top: 0px;
    font-family: "Jack";
  }

  .heroimage {
    width: 30vw;
    height: auto;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .distributionSection {
    background: rgba(0, 0, 0, 0.8); /* Consistent background for sections */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .distributionHeading {
    font-size: 2rem;
    color: #65d340; /* Neon-like color for headings */
    margin-bottom: 1rem;
  }
  
  .distributionContent {
    display: grid;
    grid-template-columns: 1fr; /* Stack the grid items on top of each other on smaller screens */
    gap: 0rem;
  }
  
  .distributionItem {
    background: rgba(255, 255, 255, 0.1); /* Subtle background for each item */
    padding: 15px;
    border-radius: 8px;
    word-wrap: break-word;
    width: 70vw;
  }
  
  .distributionText {
    color: white;
    font-size: 1rem;
    line-height: 1.6;
  }


.chartContainer {
  width: 90vw; /* Max width for chart container */
  margin: 0 auto; /* Center the chart container */
  height: 90vw; /* Set height to auto for responsive height */
}

.swap2 {
  display: none;
}

.swap3 {
  text-align: center;
  font-size: 5dvw; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 4vw;
  font-weight: 600;
}

.swap6 {
  text-align: center;
  font-size: 5dvw; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 4vw;
  font-weight: 600;
  display: flex;
}

.swap {
  text-align: center;
  font-size: 3dvw; /* Larger font size for title */
  color: #d0dbda; /* Neon-like color for title */
  margin-top: 2vw;
  font-weight: 600;
}

.tokelinks {
  display: flex;
  flex-direction: row;
  width: 60dvw;
  margin-right: 6dvw;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 3dvw;
}


  
}