@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/a11y-dark.min.css');


.chart-tool-container {
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  height: 500px;
  
}

.chart-tool-container h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #00ff00;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
}

.chart-tool-container > div {
  width: 75vw; /* 75% of the viewport width */
  height: calc(75vw / 2); /* Adjust height based on desired aspect ratio, here it's set to 2:1 */
  max-width: 1500px; /* Ensure the chart doesn't exceed a max width */
  max-height: 600px; /* Ensure the chart doesn't exceed a max height */
  min-width: 600px; /* Set a minimum width to avoid the chart becoming too small */
  min-height: 300px; /* Set a minimum height to maintain usability */
}

@media (max-width: 1400px) {
  .chart-tool-container {
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
    height: 500px;
    width: 100%;
    
  }
  
  .chart-tool-container h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #00ff00;
    font-family: 'Orbitron', sans-serif;
    font-weight: 600;
  }
  
  .chart-tool-container > div {
    width: 70vw; /* 75% of the viewport width */
    height: calc(75vw / 2); /* Adjust height based on desired aspect ratio, here it's set to 2:1 */
    max-width: 900px; /* Ensure the chart doesn't exceed a max width */
    max-height: 600px; /* Ensure the chart doesn't exceed a max height */
    min-width: 100px; /* Set a minimum width to avoid the chart becoming too small */
    min-height: 300px; /* Set a minimum height to maintain usability */
  }
}