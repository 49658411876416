@font-face {
    font-family: 'Jack';
    src: url('/public/fonts/Jack.ttf');
  }

.data-wrapper {
  width: 100dvw;
}

.morph-data-page {
    display: flex;
    align-items: center;
    color: white;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 2dvw;
    margin-bottom: 2dvw;
  }
  
  .morph-data-page h1 {
    margin-bottom: 1dvw;
    margin-top: 0dvw;
    font-family: "Jack";
    color: #65d340;
    font-size: 2.5dvw;
  }

  .morph-data-page h2 {
    margin-bottom: 1dvw;
    margin-top: 0dvw;
    font-size: 1dvw;
    font-family: "Jack";
  }
  
  .morph-data-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 2.5dvw;
    width: 100%;
    height: auto;
    margin-top: 2dvw;
    align-items: center;
    margin: auto;
  }
  
  .data-box {
    background: black;
    padding: 1dvw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.138);
    width: 14dvw;
    height: 14dvh;
    border-radius: .75dvw;
  }
  
  
  .data-box h3 {
    margin: auto;
    font-size: 1.5vw;
    color: #65d340;
    text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.3);
  }
  
  .data-box p {
    font-size: 1.1dvw;
    font-weight: 800;
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.3);
  }
  
  .link-item4 {
    padding: 0.5vw 1vw;
    background-color: black;
    color: white;
    border: none;
    border-radius: .5vw;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    text-align: center;
    font-weight: 900;
    box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
    text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
    font-size: 1.2dvw;
    width: fit-content;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    .morph-data-page {
        display: flex;
        align-items: center;
        color: white;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
        margin: auto;
        margin-top: 2dvw;
        margin-bottom: 2dvw;
      }
      
      .morph-data-page h1 {
        margin-bottom: 1dvw;
        margin-top: 0dvw;
        font-family: "Jack";
        color: #65d340;
        font-size: 3.5dvw;
      }
    
      .morph-data-page h2 {
        margin-bottom: 2dvw;
        margin-top: 0dvw;
        font-size: 2dvw;
        font-family: "Jack";
      }
      
      .morph-data-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 2dvw;
        width: 100%;
        height: auto;
        margin-top: 2dvw;
        align-items: center;
        margin: auto;
      }
      
      .data-box {
        background: black;
        padding: 1dvw;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: top;
        align-items: top;
        background-color: rgba(255, 255, 255, 0.157);
        width: 30dvw;
        height: 8dvh;
        border-radius: .75dvw;
        margin: auto;
      }
      
      
      .data-box h3 {
        margin: auto;
        font-size: 3dvw;
        color: #65d340;
        text-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.3);
      }
      
      .data-box p {
        font-size: 2dvw;
        font-weight: 800;
        text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.3);
        margin-top: 0dvw;
      }
      
      .link-item4 {
        padding: 0.5vw 1vw;
        background-color: black;
        color: white;
        border: none;
        border-radius: .5vw;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        text-align: center;
        font-weight: 900;
        box-shadow: 0 0.4vw 0.8vw rgba(0, 0, 0, 0.2);
        text-shadow: 0.2vw 0.2vw 0.23vw rgba(0, 0, 0, 0.7);
        font-size: 2dvw;
        width: fit-content;
        margin: auto;
        margin-top: 0dvw;
      }
    }