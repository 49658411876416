.modal {
    position: relative;
    z-index: 1000;
    background: black;
    align-self: center;
    color: white;
    margin: auto;
    width: 90dvw;
    height: 100dvh;
    display: flex;
    overflow: hidden;
  }
  .modalContent {
    display: flex;
    width: 100dvw;
    height: 100dvh;
    flex-direction: column;
  }
  .modalLeft {
    flex-basis: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 0px;
  }
  .modalRight {
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 3.5dvw;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 0vw;
    margin-bottom: 2dvw;
  }
  .gridStyle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4dvw;
    align-items: start;
    margin-top: 0dvw;
    margin: auto;
  }
  .attribute {
    text-align: center;
  }
  button {
    padding: 10px 20px;
    background-color: #f44336;
    border: none;
    font-size: 30px;
    cursor: pointer;
    margin-top: 8dvw;
  }

  .nftname2 {
    margin-top: 0dvw;
    margin-bottom: 4dvw;
    font-size: 5dvw;
  }