.proposal-list {
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: fit-content;
  width: 90vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .3);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 0;
  }
  
  .proposal-list h2 {
    margin-bottom: 0px;
    text-align: center;
    
  }
  
  .proposal-list ul {
    list-style: none;
    padding: 0;
    margin: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 2vw;
    
  }
  
  .proposal-list li {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 2vw;
    background-color: rgba(255, 255, 255, 0.069);
    width: 40vw;
  }
  
  .proposal-list h3 {
    margin: 0 0 10px 0;
    font-size: 1.5vw;
  }
  
  .proposal-list p {
    margin: 1vw 0;
    word-wrap: break-word; /* Ensures long words break to fit the container */
    overflow-wrap: break-word; /* Ensures long words break to fit the container */
  }
  
  .proposal-list button {
    padding: 5px 10px;
    font-size: 1vw;
    font-weight: 600;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .proposal-list button:last-child {
    margin-right: 0;
  }

  .listwrapper {
    position: relative;
    background: linear-gradient(270deg, #000000, #000000);
    background-size: 100% 100%;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    align-items: top;
    display: flex;
    justify-content: center;
    z-index: 100;
    margin-bottom: 0vw;
    box-sizing: border-box;
    padding: 2vw;
    overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
  }
  
  .listwrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: url('/public/images/website.png') repeat;
    background-size: 100vw;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the background image is behind other content */
  }

  @media (max-width: 768px) {
    .proposal-list ul {
      list-style: none;
      padding: 0;
      margin: 10px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, auto);
      gap: 2.5vw;
    }

    .proposal-list {
      display: flex;
      align-items: center;
      justify-content: top;
      flex-direction: column;
      color: white;
      border-radius: 1vw;
      box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
      padding: 1vw;
      height: fit-content;
      width: 90vw;
      gap: 1vw;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity 1s ease-in-out;
      border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
      z-index: 2;
    }
    
    .proposal-list h2 {
      margin-bottom: 5px;
      text-align: center;
    }
    
    .proposal-list li {
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 0.069);
      width: 70vw;
    }
    
    .proposal-list h3 {
      margin: 0 0 10px 0;
      font-size: 4.2vw;
    }
    
    .proposal-list p {
      margin: 2.5vw 0;
    }
    
    .proposal-list button {
      padding: 5px 10px;
      font-size: 3vw;
      font-weight: 600;
      cursor: pointer;
      background-color: #007BFF;
      color: white;
      border: none;
      border-radius: 5px;
      margin-right: 20px;
    }
    
    .proposal-list button:last-child {
      margin-right: 0;
    }
  
    .listwrapper {
      position: relative;
      background: linear-gradient(270deg, #000000, #3b1818);
      background-size: 100% 100%;
      width: 100vw;
      height: 100%;
      align-items: top;
      display: flex;
      justify-content: center;
      z-index: 100;
      margin-bottom: 0vw;
      box-sizing: border-box;
      padding: 2vw;
      overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
    }
    
    .listwrapper::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: url('/public/images/website.png') repeat;
      background-size: 100vw;
      opacity: 0.5; /* Adjust the opacity as needed */
      z-index: -1; /* Ensure the background image is behind other content */
    }
  }