/* Avatar.css */
#avatar {
    width: 100%;
    height: 100px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #00ff00, 0 0 20px #00ff00, 0 0 35px #00ff00;
    overflow: hidden;
    z-index: 10;
    display: flex;
    align-items: center;
    align-self: center;
    position: relative;
    margin: 0;
  }
  
  #waveform {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #00ff00;
  }

  @media (max-width: 1400px) {
    #avatar {
      display: none
    }
  }