.starfield {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    overflow: hidden;
    perspective: 1000px;
}

.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    animation: moveStar 10s linear infinite, twinkle 6s infinite ease-in-out;
}

@keyframes moveStar {
    0% {
        transform: translate3d(0, 0, 0) scale(0.5);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 1000px) scale(2);
        opacity: 0;
    }
}

@keyframes twinkle {
    0%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
}

.glowingStar {
    position: absolute;
    width: 2px;
    height: 2px;
    background: transparent;
    border-radius: 50%;
    animation: moveStar 10s linear infinite, twinkle 6s infinite ease-in-out;
}

.glow {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 80%);
}

/* Nebula Effect */
.nebula {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 20% 40%, rgba(243, 6, 6, 0.2), transparent),
                radial-gradient(circle at 80% 60%, rgba(13, 13, 244, 0.2), transparent),
                radial-gradient(circle at 50% 80%, rgba(16, 244, 16, 0.2), transparent);
    z-index: 0;
    animation: moveNebula 60s infinite linear;
}

@keyframes moveNebula {
    0% {
        transform: scale(1);
        opacity: 0.6;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.4;
    }
    100% {
        transform: scale(1);
        opacity: 0.6;
    }
}

/* Comet */
.comet {
    position: absolute;
    width: 4px;
    height: 4px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    border-radius: 50%;
    animation: moveComet 10s linear infinite;
}

@keyframes moveComet {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate3d(100vw, 100vh, 0) scale(0.5);
        opacity: 0;
    }
}