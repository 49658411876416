@keyframes glowingEdge {
    0% {
      box-shadow: 0 0 2.5px rgba(0, 255, 0, 0), 0 0 5px rgba(0, 255, 0, 0), 0 0 7.5px rgba(0, 255, 0, 0);
    }
    25% {
      box-shadow: -1px -1px 2.5px rgba(0, 255, 0, 0.4), 0 0 5px rgba(0, 255, 0, 0.4), 1px 1px 7.5px rgba(0, 255, 0, 0.4);
    }
    50% {
      box-shadow: -2px -2px 5px rgba(0, 255, 0, 0.6), 0 0 10px rgba(0, 255, 0, 0.6), 2px 2px 12.5px rgba(0, 255, 0, 0.6);
    }
    75% {
      box-shadow: -1px -1px 2.5px rgba(0, 255, 0, 0.4), 0 0 5px rgba(0, 255, 0, 0.4), 1px 1px 7.5px rgba(0, 255, 0, 0.4);
    }
    100% {
      box-shadow: 0 0 2.5px rgba(0, 255, 0, 0), 0 0 5px rgba(0, 255, 0, 0), 0 0 7.5px rgba(0, 255, 0, 0);
    }
  }

.chat-container {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 92%;
  }

  .uploaded-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .message {
    display: flex;
    margin-bottom: 30px;
    padding: 2px;
    border-radius: 5px;
    max-width: 90%;
    word-wrap: break-word;
    animation: glowingEdge 2s infinite linear;
  }
  
  .message.user {
    justify-content: flex-end;
    align-self: flex-end;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 10px 10px;
  }
  
  .message.assistant, .message.system {
    justify-content: flex-start;
    align-self: flex-start;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 10px 10px;
  }
  
  .message-content {
    display: inline-block;
    padding: 5px;
  }
  
  .message strong {
    display: block;
    margin-bottom: 5px;
  }