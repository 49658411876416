@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Jack';
  src: url('/public/fonts/Jack.ttf');
}

.title {
    text-align: center;
    font-family: Jack;
    font-size: 3dvw;
    color: #65d340;
    margin-bottom: 0dvw;
    margin-top: 0dvw;
  }

.container {
    padding: 0px;
    background-size: cover;
    min-height: 100vh; /* Full height */
    color: white;
    background-image: url('/public/images/website.png');
  }

.description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-align: center;
  }

.teamList {
    display: flex;
    max-width: 80vw;
  
  }

.teamList2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Four items per row */
    gap: 1.5rem; /* Increased gap for better spacing */
    list-style-type: none;
    width: 55dvw;
}

.teamItem {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .teamItem2 {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-indent: 3em;
    font-family: 'Inter', sans-serif;
    line-height: 1.9rem;
  }

.teamtext {
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  padding: 0;
  align-items: center;
  text-align: center;
}

.teamlink {
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;

}

.teamtext2 {
  font-family: 'Inter', sans-serif;
  font-size: 1.7rem;
  padding: 0;
  margin: 0;
}

.teamSection {
    display: flex;
    justify-content: left;
    margin-bottom: 2dvw;
    margin-top: 0dvw;
  }

.teamSection2 {
    display: flex;
    justify-content: right;
    margin-bottom: 0px;
  }  

.teamContainer {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    padding: 2dvw;
    border-radius: 10px;
    margin-bottom: 0dvw; /* Add space between sections */
    display: flex;
    flex-direction: column;
    margin-top: 0dvw;
  }

  .pfpimage {
    width: 15vw;
    height: 15vw;
    margin: 0;
    padding: 0;
  }

  .mtcontainer {
    display: none;
  }

.teamHeader {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    margin-bottom: 0dvw; /* Add space between sections */
    margin-top: 0dvw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

.teamimage {
    width: 6vw;
    height: 6vw;
    margin-top: 0dvw;
    margin-bottom: 0dvw;
    padding: 1dvw;
}

.mobileteam {
  display: none;
}
  

  @media (max-width: 768px) {
    .title {
      text-align: center;
      font-family: Jack;
      font-size: 4dvw;
      color: #65d340;
      margin-bottom: 0dvw;
      margin-top: 5dvw;
  }

  .container {
      display: none;
  }

  .mtcontainer {
      padding: 0px;
      background-size: cover;
      color: white;
      background-image: url('/public/images/website.png');
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      align-content: center;
      position: absolute;
      top: 22%;
      left: 20%;
      width: 63dvw;
      height: 55dvh;
      overflow-y: auto;
      overflow-x: hidden;
  }

  .description {
      font-size: 1.1rem;
      margin-bottom: 2rem;
      text-align: center;
  }

  .teamList {
      display: flex;
      width: 60dvw;
      overflow: hidden;
      overflow-y: scroll;
  }

  .teamList2 {
      display: grid;
      grid-template-columns: repeat(1, 1fr); /* Four items per row */
      gap: 1.5rem; /* Increased gap for better spacing */
      list-style-type: none;
      width: 60dvw;
      justify-content: center;
      align-items: center;
  }

  .teamItem {
      background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
      width: auto;
      word-wrap: break-word; /* Ensures text wraps within the container */
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-indent: 0px;
  }

  .teamItem2 {
      background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
      word-wrap: break-word; /* Ensures text wraps within the container */
      display: flex;
      font-size: 3dvw;
      text-align: left;
      font-family: 'Inter', sans-serif;
      line-height: 1.9rem;
      text-indent: 0px;
  }

  .teamtext {
      font-family: 'Inter', sans-serif;
      font-size: 3dvw;
      padding: 0;
      align-items: center;
      text-align: center;
  }

  .teamlink {
      font-size: 3.5dvw;
      font-family: 'Inter', sans-serif;
  }

  .teamtext2 {
      font-family: 'Inter', sans-serif;
      font-size: 1.7rem;
      padding: 0;
      margin: 0;
  }

  .teamSection {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-bottom: 2dvw;
      margin-top: 0dvw;
      width: 60dvw;
      padding: 2dvw;
  }

  .teamSection2 {
      display: flex;
      justify-content: center;
      margin-bottom: 0px;
      width: 60dvw;
      padding: 2dvw;
  }

  .pfpimage {
    width: 30vw;
    height: 30vw;
    margin: 0;
    padding: 0;
  }

  .teamHeader {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    margin-bottom: 0dvw; /* Add space between sections */
    margin-top: 0dvw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  }


