@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.1/styles/a11y-dark.min.css');

.meme-chart-tool-debug {
    background-color: black;
    color: #ffffff;
    padding: 20px;
    border: 5px solid #00ff00;
    margin: 20px 0;
    color: #00ff00;
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  }
  
  .meme-chart-tool-debug input,
  .meme-chart-tool-debug select,
  .meme-chart-tool-debug button {
    display: block;
    margin: 10px 0;
    padding: 5px;
    color: black;
  font-family: 'Orbitron', sans-serif;
  }

  .meme-chart-tool-debug h3 {
    margin-bottom: 20px;
    font-weight: 600;
}

  .meme-chart-tool-debug button {
    display: block;
    margin: 10px 0;
    padding: 5px;
    background-color: #00ff00;
  font-family: 'Orbitron', sans-serif;
  }

  @media (max-width: 1400px) {
   
    }