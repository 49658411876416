/* Utilities.module.css */

.container {
    padding: 40px;
    background-size: cover;
    min-height: 100vh; /* Full height */
    color: white;
  }


  .toggleAllButton {
    background-color: #00ffea; /* Match this with your theme color */
    color: #000;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .toggleAllButton:hover {
    background-color: #00d9c0; /* Slightly darker for hover effect */
  }
  .content {
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background for readability */
    border-radius: 15px;
    padding: 2rem;
  }

  .utilityHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .utilityContentClosed {
    display: none;
    transition: max-height 0.3s ease;
  }
  
  .utilityContentOpen {
    display: block; /* Or use max-height for a smooth transition */
    transition: max-height 0.3s ease;
  }

  .overviewContent {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem; /* Increased gap for better spacing */
  }
  
  .overviewItem {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    margin: 10px 0; /* Adds margin between items */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
  }
  
  
  .title {
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Inter', sans-serif;
    font-size: 4rem;
    color: #00ffea;
  }
  
  .utilitiesSection {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem; /* Add space between sections */
  }
  
  .utilitiesHeading {
    font-size: 2.5rem; /* Larger font size for headings */
    color: #00ffea;
    margin-bottom: 1.5rem; /* Increased margin for better spacing */
    text-align: center; /* Centers the heading text */
  }
  
  .description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-align: center;
    font-family: 'Inter', sans-serif;
  }
  
  .utilitiesList {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    gap: 1.5rem; /* Increased gap for better spacing */
    list-style-type: none;
    line-height: 1.8rem;
  }
  
  .utilityItem {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
    font-family: 'Inter', sans-serif;
  }

  @media (max-width: 1200px) {
    .utilitiesList {
      grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .utilitiesSection {
      padding: 1rem;
    }
  
    .utilitiesHeading {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .utilitiesList {
      grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns for small screens */
      list-style-type: none;
    }
  
    .utilityItem {
      padding: 0.5rem;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    .utilitiesList {
      grid-template-columns: 1fr; /* Single column for extra small screens */
      list-style-type: none;
    }
  
    .utilityItem {
      padding: 10px; /* Smaller padding for smaller screens */
    }
  }
  