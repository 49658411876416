@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
/* ImageScroller.css */

.alien {
  background: url('../styles/Filterpage.gif') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  position: absolute; /* Ensure your layout uses this as a positioning context */
}

.testerr{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aliennav {
  display: flex;
  justify-Content: center;
  padding: 1rem;
  margin-Bottom:10px;
  position: fixed;
  top: 14%;
  left: 35%;
}

.alienNavText {
  color: white;
  margin-Right: 1rem;
  font-Size: 1.5rem;
  margin-left: 1.5rem;
}

.alien2 {
  height: 63vh;
  top: 15%;
  position: fixed;
  overflow: hidden;
}



.alien3 {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
.scroller-wrapper {
    max-width: 100%;
    overflow: hidden !important;
    position: relative;
    height: 150px;
  }
  
  .scroller {
    display: flex;
    animation: scroll 30s linear infinite;
    white-space: wrap;
    overflow: auto;
    max-width: 100vw;
  }
  
  .scroller img {
    flex: 0 0 auto;
    height: 200px;
    margin-right: 10px;
    margin-bottom: 2rem;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% / 3)); /* Change depending on your total content width */
    }
  }
  
  .eden {
    width: 10vw;
    height: auto;
  }

  .tensor {
    width: 9.85vw;
    height: auto;
  }
  
  .hoomans {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem; /* Add space between sections */
    display: flex;
    flex-direction: column;
  }

  .hoomansTitle {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-wrap: wrap;
  }

  .hoomansTitle2 {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .hoomansBox {
    background: rgba(88, 88, 88, 0.3); /* Darker background for better readability */
    margin-bottom: 2rem; /* Add space between sections */
    display: flex;
    flex-direction: column;
    width: 30vw;
    border-radius: 10px;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
  }

  .hoomansBox2 {
    background: rgba(88, 88, 88, 0.3); /* Semi-transparent darker background */
    margin-bottom: 2rem; /* Add space between sections */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr)); /* Creates as many columns as needed, each at least 45% of container width */
    width: 30vw; /* Adjust width as needed */
    border-radius: 10px;
    padding: 2rem; /* Adjust padding */
    gap: 50px; /* Space between grid items */
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
  
  .hoomansText {
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    color: #ffffff;
    align-items: center;
    text-align: center;
  }
  
  .title2 {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 4rem;
    color: #00ffea;
  }

.title {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 4rem;
    color: #00ffea;
  }

.container {
    padding: 40px;
    background-size: cover;
    min-height: 100vh; /* Full height */
    color: white;
    max-width: 100vw;
  }

.description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-align: center;
  }

.teamList {
    display: flex;
    max-width: 80vw;
  
  }

.teamList2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Four items per row */
    gap: 1.5rem; /* Increased gap for better spacing */
    list-style-type: none;
}

.teamItem {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .teamItem2 {
    background: rgba(255, 255, 255, 0.1); /* Adds a subtle background to each item */
    padding: 15px; /* Adds padding inside each item */
    border-radius: 8px; /* Rounds the corners of the background */
    word-wrap: break-word; /* Ensures text wraps within the container */
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-indent: 3em;
    font-family: 'Inter', sans-serif;
    line-height: 1.9rem;
  }

.teamtext {
  font-family: 'Inter', sans-serif;
  font-size: 2rem;
  padding: 0;
}

.teamlink {
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
}

.sheetLink {
  font-size: 1.5rem;
  margin-left: 20px;
}

.sheetLink2 {
  font-size: 1.5rem;
}

.teamtext2 {
  font-family: 'Inter', sans-serif;
  font-size: 1.7rem;
  padding: 0;
  margin: 0;
}

.teamSection {
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
  }

.teamSection2 {
    display: flex;
    justify-content: right;
    margin-bottom: 50px;
    
  }  

.teamContainer {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    padding: 4rem;
    border-radius: 10px;
    margin-bottom: 2rem; /* Add space between sections */
    display: flex;
    flex-direction: column;
    
  }

  .pfpimage {
    width: 15vw;
    height: 15vw;
    margin: 0;
    padding: 0;
  }


.teamHeader {
    background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem; /* Add space between sections */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  

.teamimage {
    width: 6vw;
    height: 6vw;
}



  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .utilitiesSection {
      padding: 1rem;
    }
  
    .utilitiesHeading {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .teamList {
      grid-template-columns: repeat(2, 1fr); /* Adjust the number of columns for small screens */
      list-style-type: none;
    }
  
    .teamItem {
      padding: 0.5rem;
      font-size: 0.9rem;
    }

    .teamHeader {
        background: rgba(0, 0, 0, 0.8); /* Darker background for better readability */
        padding: 2rem;
        border-radius: 10px;
        margin-bottom: 2rem; /* Add space between sections */
      }
  }
