.governance-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  border-radius: 1vw;
  box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
  padding: 1vw;
  height: 100%;
  width: 90vw;
  gap: 1vw;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity 1s ease-in-out;
  border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
  z-index: 2;
}

.governance-page h2, .governance-page p {
  text-align: center;
  margin-bottom: 0px;
  font-weight: 600;
  margin-top: 0vw;
}

.guidelines-section {
  width: 70vw;
  text-align: left;
  margin-top: 0px;
  background-color: rgba(0, 0, 0, .5);
  padding: 20px;
  border-radius: 2vw;
}

.guidelines-section h2 {
  margin-bottom: 10px;
}

.guidelines-section ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.guidelines-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.guidelines-section li {
  margin-bottom: 10px;
}

.govwrapper {
  position: relative;
  background: linear-gradient(270deg, #000000, #000000);
  background-size: 100% 100%;
  width: 100vw;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 100;
  margin-bottom: 0vw;
  box-sizing: border-box;
  padding: 2vw;
  overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
}

.govwrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: url('/public/images/website.png') repeat;
  background-size: 100vw;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: -1; /* Ensure the background image is behind other content */
}

@media (max-width: 768px) {
  .governance-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    border-radius: 1vw;
    box-shadow: 0 0.4vw 1vw rgba(0, 0, 0, 0.3), 0 0.6vw 2vw rgba(0, 0, 0, 0.2);
    padding: 2vw;
    height: 100%;
    width: 90vw;
    gap: 1vw;
    background-color: rgba(0, 0, 0, .8);
    transition: opacity 1s ease-in-out;
    border: 0.1vw solid rgba(0, 0, 0, 0.1); /* Slight border to enhance 3D effect */
    z-index: 2;
  }
  
  .governance-page h2, .governance-page p {
    text-align: center;
    margin-bottom: 0px;
  }
  
  .guidelines-section {
    width: 80vw;
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    
  }
  
  .guidelines-section h2 {
    margin-bottom: 10px;
  }
  
  .guidelines-section ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .guidelines-section ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .guidelines-section li {
    margin-bottom: 10px;
  }
  
  .govwrapper {
    position: relative;
    background: linear-gradient(270deg, #000000, #3b1818);
    background-size: 100% 100%;
    width: 100vw;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 100;
    margin-bottom: 0vw;
    box-sizing: border-box;
    padding: 2vw;
    overflow: hidden; /* Ensure the pseudo-element doesn't overflow */
  }
  
  .govwrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: url('/public/images/website.png') repeat;
    background-size: 100vw;
    opacity: 0.5; /* Adjust the opacity as needed */
    z-index: -1; /* Ensure the background image is behind other content */
  }
}