.modal {
    position: fixed;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: black;
    color: white;
    padding: 20px;
    width: 56%;
    height: 57.5%;
    display: flex;
    overflow: hidden;
  }
  .modalContent {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .modalLeft {
    flex-basis: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalRight {
    flex-basis: 60%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    justify-content: space-between;
  }
  .gridStyle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: start;
  }
  .attribute {
    text-align: left;
  }
  button {
    padding: 10px 20px;
    background-color: #f44336;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }